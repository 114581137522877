import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { configDesc, updateRechargeConfig } from './apis/requestsApi';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";

type Recharge = {
    id: number;
    operator: string;
    brand: string;
    denomination: number;
    totalDisccount: number;
    totalDisccountProvider: number;
    totalDisccountCustomer: number;
    quickSelect: boolean;
    visible: boolean | null;
    operators: string;
};

interface ValidationResult {
    isValid: boolean;
    message?: string;
}

const RechargesDescConfig: React.FC = () => {
    const [recharges, setRecharges] = useState<Recharge[]>([]);
    const [uniqueBrands, setUniqueBrands] = useState<string[]>([]);
    const [filteredRecharges, setFilteredRecharges] = useState<Recharge[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<string>("");
    const [totalDisccountValues, setTotalDisccountValues] = useState<Record<number, string | null>>({});
    const [totalDisccountProviderValues, setTotalDisccountProviderValues] = useState<Record<string, string>>({});
    const [totalDisccountCustomerValues, setTotalDisccountCustomerValues] = useState<Record<string, string>>({});
    const [quickSelectValues, setQuickSelectValues] = useState<Record<number, boolean>>({});
    const [visibleValues, setVisibleValues] = useState<Record<number, boolean>>({});
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const { recharges } = await configDesc();
                setRecharges(recharges);
                const brandSet = new Set(recharges.map((item: { brand: any; }) => item.brand));
                const brands = Array.from(brandSet) as string[];
                setUniqueBrands(brands);
                setSelectedBrand(brands[0]);
            } catch (err) {
                setError(err as Error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const filtered = recharges.filter(r => r.brand === selectedBrand);
        const sorted = filtered.sort((a, b) => a.denomination - b.denomination);
        setFilteredRecharges(sorted);
    }, [selectedBrand, recharges]);

    const handleSend = () => {
        if (!validateAllDiscounts()) {
            return;
        }

        const relevantFields = filteredRecharges.map(recharge => ({
            id: recharge.id,
            totalDisccount: parseFloat(totalDisccountValues[recharge.id] ?? recharge.totalDisccount.toString()),
            totalDisccountProvider: parseFloat(totalDisccountProviderValues[recharge.id.toString()] || recharge.totalDisccountProvider.toString()),
            totalDisccountCustomer: parseFloat(totalDisccountCustomerValues[recharge.id.toString()] || recharge.totalDisccountCustomer.toString()),
            quickSelect: quickSelectValues[recharge.id] ?? recharge.quickSelect,
            visible: visibleValues[recharge.id] ?? recharge.visible
        }));

        const formattedData = {
            recharges: relevantFields
        };

        Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
                <i class="fas fa-spinner fa-spin"></i>
                <span>Actualizando configuración...</span>
            </div>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        updateRechargeConfig(formattedData)
            .then(response => {
                console.log('Actualizado exitosamente:', response);
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: '¡Actualizado!',
                    text: 'La actualización se completó con éxito.'
                });
            })
            .catch(error => {
                console.error('Error al actualizar:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar. Por favor intenta de nuevo.'
                });
            });
    };

    const isValidTotalDiscount = (
        recharge: Recharge,
        totalDisccount: string,
        totalDisccountProvider: string,
        totalDisccountCustomer: string
    ): ValidationResult => {
        const providerValue = parseFloat(totalDisccountProvider) || 0;
        const customerValue = parseFloat(totalDisccountCustomer) || 0;
        const totalDiscount = providerValue + customerValue;
        const enteredTotalDiscount = parseFloat(totalDisccount) || 0;

        if (totalDiscount > enteredTotalDiscount) {
            const discountDifference = (totalDiscount - enteredTotalDiscount).toFixed(2);
            return {
                isValid: false,
                message: `La suma de los descuentos excede el descuento total permitido por ${discountDifference}.`,
            };
        }

        return { isValid: true };
    };

    const validateAndSetTotalDisccount = (recharge: Recharge, value: string | null) => {
        if (value === null || value.trim() === "") {
            setTotalDisccountValues(prev => ({ ...prev, [recharge.id]: "" }));
            return;
        }
    
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue) || parsedValue < 0) {
            Swal.fire({
                icon: 'error',
                title: 'Valor Inválido',
                text: 'El descuento total debe ser un número válido y no negativo.',
            });
            return;
        }
    
        setTotalDisccountValues(prev => ({ ...prev, [recharge.id]: value }));
    };
    

    const validateAndSet = (
        recharge: Recharge,
        type: 'totalDisccountProvider' | 'totalDisccountCustomer',
        value: string | null,
        setTotalDisccountProviderValues: React.Dispatch<React.SetStateAction<Record<string, string>>>,
        setTotalDisccountCustomerValues: React.Dispatch<React.SetStateAction<Record<string, string>>>,
        totalDisccountProviderValues: Record<string, string>,
        totalDisccountCustomerValues: Record<string, string>
    ): void => {
        const otherValue = type === 'totalDisccountProvider'
            ? totalDisccountCustomerValues[recharge.id] || recharge.totalDisccountCustomer.toString()
            : totalDisccountProviderValues[recharge.id] || recharge.totalDisccountProvider.toString();

        const adjustedValue = value || "0";

        const totalDisccount = totalDisccountValues[recharge.id] || recharge.totalDisccount.toString();

        const validationResult = isValidTotalDiscount(
            recharge,
            totalDisccount,
            type === 'totalDisccountProvider' ? adjustedValue : otherValue,
            type === 'totalDisccountCustomer' ? adjustedValue : otherValue
        );

        if (!validationResult.isValid) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: validationResult.message,
            });
            return;
        }

        if (type === 'totalDisccountProvider') {
            setTotalDisccountProviderValues(prev => ({ ...prev, [recharge.id]: adjustedValue }));
        } else {
            setTotalDisccountCustomerValues(prev => ({ ...prev, [recharge.id]: adjustedValue }));
        }
        validateAllDiscounts();
    };

    const handleBrandChange = (brand: string) => {
        setSelectedBrand(brand);
    };

    const validateAllDiscounts = (): boolean => {
        for (const recharge of filteredRecharges) {
            const totalDiscount = totalDisccountValues[recharge.id] || recharge.totalDisccount.toString();
            const providerDiscount = totalDisccountProviderValues[recharge.id] || recharge.totalDisccountProvider.toString();
            const customerDiscount = totalDisccountCustomerValues[recharge.id] || recharge.totalDisccountCustomer.toString();

            const validationResult = isValidTotalDiscount(
                recharge,
                totalDiscount,
                providerDiscount,
                customerDiscount
            );

            if (!validationResult.isValid) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error de validación',
                    text: validationResult.message,
                });

                setIsSaveButtonDisabled(true);
                return false;
            }
        }

        setIsSaveButtonDisabled(false);
        return true;
    };

    const handleTotalDiscountChange = (
        recharge: Recharge,
        type: keyof Pick<Recharge, 'totalDisccountProvider' | 'totalDisccountCustomer'>,
        value: string
    ) => {
        const setter = type === 'totalDisccountProvider'
            ? setTotalDisccountProviderValues
            : setTotalDisccountCustomerValues;

        setter(prev => ({ ...prev, [recharge.id.toString()]: value }));
    };

    const handleTotalDiscountBlur = (
        recharge: Recharge,
        type: keyof Pick<Recharge, 'totalDisccountProvider' | 'totalDisccountCustomer'>
    ) => {
        const value = type === 'totalDisccountProvider'
            ? totalDisccountProviderValues[recharge.id.toString()]
            : totalDisccountCustomerValues[recharge.id.toString()];

        validateAndSet(
            recharge,
            type,
            value,
            setTotalDisccountProviderValues,
            setTotalDisccountCustomerValues,
            totalDisccountProviderValues,
            totalDisccountCustomerValues
        );
    };

    const handleTotalDisccountBlur = (recharge: Recharge) => {
        const value = totalDisccountValues[recharge.id] || recharge.totalDisccount.toString();

        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue) || parsedValue < 0) {
            Swal.fire({
                icon: 'error',
                title: 'Valor Inválido',
                text: 'El descuento total debe ser un número válido y no negativo.',
            });
            return;
        }

        setTotalDisccountValues(prev => ({ ...prev, [recharge.id]: value }));

        validateAllDiscounts();
    };

    if (error) {
        return <div>Ocurrió un error: {error.message}</div>;
    }

    return (
        <div>
            <div className="card mb-5 mb-xl-10 mt-10" id="kt_profile_details_view">
                <div className="card-header">
                    <h3 className="card-title">Configurar Descuentos</h3>
                </div>
                <div className="card-body">
                    {/* Contenedor principal de las pestañas */}
                    <ul className="nav nav-tabs ml-3 mr-3" id="myTab" role="tablist">
                        {uniqueBrands.map((brand, index) => (
                            <li className="nav-item cursor-pointer" key={index}>
                                <a
                                    className={`nav-link ${selectedBrand === brand ? 'active bg-primary text-white' : ''}`}
                                    onClick={() => handleBrandChange(brand)}
                                >
                                    {brand}
                                </a>
                            </li>
                        ))}
                    </ul>

                    {/* Contenido de las pestañas */}
                    <div className="tab-content ml-3 mr-3 mt-10" id="myTabContent">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Denominación</th>
                                    <th>Porcentaje de descuento</th>
                                    <th>Porcentaje de descuento Equality</th>
                                    <th>Porcentaje de descuento cliente</th>
                                    <th>Selección rápida</th>
                                    <th>Es Visible</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRecharges.map((recharge, index) => (
                                    <tr key={index}>
                                        <td>{recharge.denomination}</td>

                                        <td>
                                            <input
                                                type="number"
                                                name="totalDisccount"
                                                className="form-control"
                                                value={totalDisccountValues[recharge.id] ?? recharge.totalDisccount.toString()}
                                                onChange={(e) => validateAndSetTotalDisccount(recharge, e.target.value)}
                                                onBlur={() => handleTotalDisccountBlur(recharge)}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="number"
                                                name="totalDisccountProvider"
                                                className="form-control"
                                                value={totalDisccountProviderValues[recharge.id.toString()] ?? recharge.totalDisccountProvider.toString()}
                                                onChange={(e) => handleTotalDiscountChange(
                                                    recharge,
                                                    'totalDisccountProvider',
                                                    e.target.value
                                                )}
                                                onBlur={() => handleTotalDiscountBlur(
                                                    recharge,
                                                    'totalDisccountProvider'
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="totalDisccountCustomer"
                                                className="form-control"
                                                value={totalDisccountCustomerValues[recharge.id.toString()] ?? recharge.totalDisccountCustomer.toString()}
                                                onChange={(e) => handleTotalDiscountChange(
                                                    recharge,
                                                    'totalDisccountCustomer',
                                                    e.target.value
                                                )}
                                                onBlur={() => handleTotalDiscountBlur(
                                                    recharge,
                                                    'totalDisccountCustomer'
                                                )}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <div className="custom-control custom-checkbox d-inline-block">
                                                <input
                                                    type="checkbox"
                                                    id={`quickSelect-${recharge.id}`}
                                                    name="quickSelect"
                                                    className='form-check-input border border-2 rounded'
                                                    style={{ backgroundColor: 'var(--kt-primary)' }}
                                                    checked={quickSelectValues[recharge.id] ?? recharge.quickSelect}
                                                    onChange={(e) => setQuickSelectValues(prev => ({ ...prev, [recharge.id]: e.target.checked }))}
                                                />
                                                <label className="custom-control-label" htmlFor={`quickSelect-${recharge.id}`}></label>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div className="custom-control custom-checkbox d-inline-block">
                                                <input
                                                    type="checkbox"
                                                    id={`visible-${recharge.id}`}
                                                    name="visible"
                                                    className='form-check-input border border-2 rounded'
                                                    style={{ backgroundColor: 'var(--kt-primary)' }}
                                                    checked={visibleValues[recharge.id] ?? recharge.visible}
                                                    onChange={(e) => setVisibleValues(prev => ({ ...prev, [recharge.id]: e.target.checked }))}
                                                />
                                                <label className="custom-control-label" htmlFor={`visible-${recharge.id}`}></label>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button
                                style={{ float: 'right' }}
                                disabled={isSaveButtonDisabled}
                                onClick={handleSend}
                                className="btn btn-primary mt-3"
                            >
                                Guardar Configuración {selectedBrand}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { RechargesDescConfig };
